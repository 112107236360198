<template>
  <div>
    <v-dialog
      v-model="cameraDialog"
      persistent
      class="camera-dialog"
      width="598"
    >
      <v-card
        class="camera-stream-card"
        :class="$vuetify.breakpoint.xsOnly ? 'camera-stream-card-mobile' : ''"
      >
        <v-card-title class="card-title">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-center align-center">
                <img
                  @click="closeCameraDialog()"
                  src="@/assets/icons/close-icon.svg"
                  height="22"
                  width="22"
                  alt="close-icon"
                  class="cursor-pointer ml-auto"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="card-body">
          <video
            id="video-stream"
            class="camera-stream"
            autoplay
            muted
            playsinline
          ></video>
          <v-btn
            :disabled="videoStream == null"
            @click="captureImage()"
            class="capture-btn"
            absolute
            bottom
            fab
          >
            <img
              src="@/assets/icons/capture-icon.svg"
              height="20"
              width="20"
              alt="capture-icon"
            />
          </v-btn>
        </v-card-text>
        <v-card-actions class="card-footer"> Capture Image </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { showSimpleErrorMessage } from "../../utils/showError";
export default {
  name: "Camera",
  data() {
    return {
      isCameraOpen: false,
      isLoading: false,
      cameraDialog: false,
      imageSrc: null,
      videoElement: null,
      videoStream: null,
      screenshotFormat: "image/jpeg",
      binaryFile: null,
    };
  },
  mounted() {
    this.openCamera();
  },
  watch: {
    imageSrc(val) {
      if (val !== null) {
        this.stopCameraStream();
      }
    },
    cameraDialog(val) {
      if (val == true) {
        setTimeout(() => {
          this.videoElement = document.getElementById("video-stream");
          this.videoElement.srcObject = this.videoStream;
        }, 500);
      }
    },
  },

  methods: {
    /**
     * @Description
     * 1. this method load the video stream and open the camera
     * @param error (void)
     * @return none
     **/
    openCamera() {
      if (navigator.mediaDevices?.getUserMedia) {
        let videoConstraints = {
          facingMode: { ideal: "user" },
          frameRate: 30,
          width: 1920,
          height: 1080,
          require: ["width", "height"],
        };

        if (this.$vuetify.breakpoint.mdAndUp) {
          videoConstraints = {
            width: 1280,
            height: 720,
            require: ["width", "height"],
            facingMode: { ideal: "user" },
          };
        }
        navigator.mediaDevices
          .getUserMedia({
            video: videoConstraints,
          })
          .then((stream) => {
            this.cameraDialog = true;
            this.videoStream = stream;
          })
          .catch(() => {
            this.closeCameraDialog();
            showSimpleErrorMessage(
              "Your browser don't have camera support or there is an errors."
            );
          });
      }
    },
    /**
     * @description
     * 1. This method draws image on canvas to show proof preview
     */
    async captureImage() {
      try {
        const video = document.getElementById("video-stream");
        const canvas = document.createElement("canvas");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx1 = canvas.getContext("2d");
        ctx1.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.imageSrc = canvas.toDataURL(this.screenshotFormat);

        this.binaryFile = await this.imageBase64ToImageBlob(
          this.imageSrc,
          this.screenshotFormat
        );
        let data = {
          base64Image: this.imageSrc,
          file: this.binaryFile,
        };
        this.$emit("attached-image", data);
        this.closeCameraDialog();
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description
     * it is used to convert base64 file to blob file
     */
    imageBase64ToImageBlob(base64, type) {
      // Remove the data URL prefix (e.g., "data:image/png;base64,")
      const base64WithoutPrefix = base64.replace(/^data:[^;]+;base64,/, "");

      // Convert base64 to a binary array
      const binaryData = atob(base64WithoutPrefix);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob from the binary data
      return new Blob([uint8Array], { type: type });
    },

    /**
     * Stops the camera stream by removing the video source from the video element
     * and stopping all tracks in the video stream. Sets the video stream to null.
     *
     * @method stopCameraStream
     */
    stopCameraStream() {
      let videoElement = document.getElementById("video-stream");
      if (videoElement) {
        videoElement.srcObject = null;
      }
      this.videoStream?.getTracks().forEach((track) => track.stop());
      this.videoStream = null;
    },

    /**
     * Reloads the current page, effectively simulating a "go back" action.
     *
     * @method goBack
     */
    goBack() {
      location.reload();
    },

    /**
     * Closes the camera dialog by stopping the camera stream and setting the camera dialog
     * visibility to false. Emits a "close-camera-dialog" event.
     *
     * @method closeCameraDialog
     */
    closeCameraDialog() {
      this.stopCameraStream();
      this.cameraDialog = false;
      this.$emit("close-camera-dialog");
    },
  },
  async beforeDestroy() {
    await this.stopCameraStream();
  },
};
</script>

<style scoped>
.camera-dialog {
  width: 598px;
  height: 465;
}
.camera-stream-card {
  padding: 10px 40px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.camera-stream-card-mobile {
  padding: 10px 15px;
}
.camera-stream-card:deep(.v-card__title) {
  padding: 16px 0 10px !important;
}
.camera-stream-card:deep(.v-card__text) {
  padding: 0 !important;
}
.camera-stream-card:deep(.v-card__actions) {
  padding-top: 40px !important;
}
.card-title {
  display: flex;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.card-body {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  padding: 0;
}
.card-footer {
  color: #292b30;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 30px;
}
.capture-btn {
  background: radial-gradient(50% 50% at 50% 50%, #3e7ef6 0%, #324ee0 100%);
}
.camera-stream {
  min-width: 100%;
  min-height: 100%;
  min-width: fill-available;
  min-width: -webkit-fill-available;
  min-width: -moz-fill-available;
  object-fit: cover;
  border-radius: 6px;
  transform: scaleX(-1);
}
</style>
